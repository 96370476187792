import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
  Container,
  ListItemButton,
  Button,
  TextField,
  Stack 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Header from "../components/Header";
import HeaderLogin from "../layouts/SidebarLayout/Header/Buttons/Login";
import HeaderSearch from "../layouts/SidebarLayout/Header/Buttons/Search";
import EmailIcon from '@mui/icons-material/Email';
import { Helmet } from "react-helmet-async";
import { useDb } from "src/contexts/DbContext";
/* import Login from "../components/Login"; */


interface HomePageProps {
    isLoggedIn: boolean;
}


const HomePage: React.FC<HomePageProps> = ({ isLoggedIn }) => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const menuItems = ["Home", "About Us", "Blog", "Profile", "Contact Us"];

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const {t} = useDb();


  return (
    <>
    <Helmet>
        <title>The Specialist - Home</title>
    </Helmet>
    <Box sx={{ backgroundColor: "#017432", color: "#fff", padding: "0.5rem 0", }}>
      <Box>
        <Grid container spacing={0} sx={{margin: "0.5rem" }}>
          <Grid size={{ xs: 6, md: 6, }}>
            <Typography variant="body1" sx={{padding: "1rem 0", fontFamily: "Oswald, sans-serif"}}>
              {/* <EmailIcon/> */}
              <Link href="tel:0175 - 169 76 991" underline="none" color="inherit">
              0175 - 169 76 991
              </Link>
            </Typography>
          </Grid>
          <Grid size={{ xs: 6, md: 6 }} textAlign="right">
            {/* <Link href="signup.html" underline="none" color="inherit">
              Login
            </Link> */}
            {/* Header */}
            {/* <Header isLoggedIn={isLoggedIn} logoSrc={'/static/images/logo/thespecialist.png'} /> */}
            <HeaderLogin />
          </Grid>
        </Grid>
      </Box>
    </Box>

    <Box sx={{ margin: "0rem 0" }}>
      <AppBar position="static" sx={{ backgroundColor: "#000", color: "#fff", padding: "1rem 0" }}>
        <Toolbar sx={{ minHeight: 400, margin: "1rem" }}>
          {/* Title or Logo */}
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            <Link href="index.html">
                <img src="static/images/logo/thespecialist.png" alt="Logo" style={{ maxWidth: "250px" }} />
              </Link>
          </Typography>


          {/* Mobile Menu Button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { sm: "none" } }}
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", sm: "flex" }, gap: 2, fontFamily: "Oswald, sans-serif" }}>
            {menuItems.map((item, index) => (
              <Link
                key={index}
                /* href={`${item.toLowerCase().replace(/\s+/g, "-")}.html`} */
                href="#"
                color="inherit"
                underline="none"
                sx={{
                  fontSize: "1.2rem",
                  "&:hover": {
                    color: "#01bb50"
                  },
                }}
              >
                {item}
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item, index) => (
              <ListItemButton key={index} component="a" href={`${item.toLowerCase().replace(/\s+/g, "-")}.html`}>
                <ListItemText primary={item} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>

    
    
    {/* Search Profile Section */}
    <Box sx={{
        backgroundImage: 'url(static/images/home/banner-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        height: '500px',
      }}>
        <Box className="container">
          <Grid container spacing={0}>
            <Grid size={12}>
              <Box className="search-profile-content" sx={{
                textAlign: 'center', 
                color: '#ffffff', 
                paddingTop: '9%'
                }}>
                {/* <Typography variant="h2" sx={{ color: '#ffffff', textTransform: 'uppercase', fontFamily: 'Oswald-Medium', fontSize: '30px', paddingBottom: '20px' }}>
                Search the best <span style={{ color: '#017432' }}>Movie Industry</span> Professionals
                </Typography> */}

                <Typography variant="h3" sx={{ mb: 2, textTransform: "uppercase", paddingBottom: '24px', fontFamily: "Oswald, sans-serif"}}>
                {t('Search the best')} <span style={{ color: '#01bb50' }}>{t('Movie Industry')}</span> {t('Professionals')}
                </Typography>
                <form>
                <TextField
                    fullWidth 
                    variant="outlined"
                    placeholder="Search Here......."
                    sx={{
                    backgroundColor: '#000000',
                    width: '500px',
                    padding: '0px',
                    border: 'solid 2px #017432',
                    borderRadius: '13px',
                    fontSize: '16px',
                    color: '#ffffff',
                    maxWidth: '90%',
                    }}
                />
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Find Movie Section */}
      <Box sx={{ padding: "4rem 0", backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Box className="container">
          <Grid container spacing={2}>
            <Grid size={12} textAlign="center">
              <Box className="find-movie-content">
                <Typography variant="h2" sx={{ mb: 2, textTransform: "uppercase", fontFamily: "Oswald, sans-serif" }}>
                  {t('Find the best Movie Industry Professionals')}
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, fontSize: "22px",
                padding: "20px 0",
                color: "#01bb50", }}>
                  {t('Your platform to connect with best talents outside')}
                </Typography>
                <Button variant="contained" color="primary" href="#" sx={{ background: "#ffffff",
                padding: "2px 40px",
                fontFamily: "Oswald, sans-serif",
                fontSize: "16px",
                marginTop: "6px",
                display: "inline-block",
                borderRadius: "18px",
                borderBottom: "solid 9px #017432",
                color: "#000000",
                textTransform: "uppercase", }}>
                  {t('Start Now')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Contact Specialist Section */}
      <Box sx={{ padding: "0rem 0", backgroundColor: "#000" }}>
        <Box>
          <Grid container spacing={3}>
            <Grid container spacing={{md:4, xs:2}}>
              <Box className="contact-specilist-content" sx={{background: "#000000",
                padding: "40px",}}>
                <Typography variant="body1" sx={{ mb: 2 }}>
                {t('Lorem Ipsum is simply dummy text of the printing and typesetting industry. ')}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                {t('Lorem Ipsum is simply dummy text of the printing and typesetting industry.')}
                </Typography>
              </Box>
            </Grid>

            {/* Images */}
            <Grid container size={12} spacing={2} textAlign="center">
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Box className="contact-specilist-img" sx={{background: "#000000", padding: "20px"}}>
                        <img src="static/images/home/movie-img-1.jpg" alt="Movie 1" style={{ width: "100%", borderRadius: "20px" }} />
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Box className="contact-specilist-img" sx={{background: "#000000", padding: "20px",}}>
                        <img src="static/images/home/movie-img-2.jpg" alt="Movie 2" style={{ width: "100%", borderRadius: "20px" }} />
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                    <Box className="contact-specilist-img" sx={{background: "#000000", padding: "20px",}}>
                        <img src="static/images/home/movie-img-3.jpg" alt="Movie 3" style={{ width: "100%", borderRadius: "20px" }} />
                    </Box>
                </Grid>
            </Grid>

            <Grid size={12} textAlign="center">
              <Box className="contact-specilist-btn" sx={{textAlign: "center",
                paddingTop: "10px", paddingBottom: "50px",}}>
                <Button variant="contained" color="primary" href="#" sx={{ background: "#ffffff",
                padding: "2px 40px",
                fontFamily: "Oswald, sans-serif",
                fontSize: "16px",
                marginTop: "6px",
                display: "inline-block",
                borderRadius: "18px",
                borderBottom: "solid 9px #017432",
                color: "#000000",
                textTransform: "uppercase", }}>
                  {t('Contact the Specialist')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Social Icons Section */}
      <Box sx={{ padding: "4rem 0",  backgroundImage: 'url(static/images/home/bg.jpg)',
        backgroundRepeat: 'repeat', }}>
        <Box className="container" sx={{padding: "20px"}}>
          <Grid container spacing={3}>
            <Grid size={6} sx={{ textAlign: "center", color: "#0077b5" }}>
              <Button href="#"sx={{ background: "#ffffff",
                padding: "2px 40px",
                fontFamily: "Oswald, sans-serif",
                fontSize: "16px",
                marginTop: "6px",
                display: "inline-block",
                borderRadius: "18px",
                borderBottom: "solid 9px #017432",
                color: "#000000",
                textTransform: "uppercase", }}>{t('FOLLOW US ON LinkedIn')}</Button>
            </Grid>
            <Grid size={6} sx={{ textAlign: "center", color: "#FF0000" }}>
              <Button href="#"sx={{ background: "#ffffff",
                padding: "2px 40px",
                fontFamily: "Oswald, sans-serif",
                fontSize: "16px",
                marginTop: "6px",
                display: "inline-block",
                borderRadius: "18px",
                borderBottom: "solid 9px #017432",
                color: "#000000",
                textTransform: "uppercase", }}>{t('FOLLOW US ON Youtube')}</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    
    <Box sx={{ backgroundColor: "#015224", color: "#fff", padding: "1rem 0" }}>
      <Container>
      <Box sx={{ backgroundColor: "#015224", color: "#fff", padding: "1rem 0", mb: 1 }}>
          <Typography variant="h3" sx={{ mb: 2, fontFamily: "Oswald, sans-serif" }}>{t('Connect with us')}</Typography>
          <Stack direction="row" spacing={2}>
            <Link href="#" target="_blank">
              <FacebookIcon sx={{
                color: '#ffffff', // Change the color
                fontSize: '30px', // Adjust the size
                '&:hover': {
                  color: '#01bb50', // Change the hover color
                  transform: 'scale(1.1)', // Add a hover effect
                },
              }}/>
            </Link>
            <Link href="#" target="_blank">
              <InstagramIcon sx={{
                color: '#ffffff', // Change the color
                fontSize: '30px', // Adjust the size
                '&:hover': {
                  color: '#01bb50', // Change the hover color
                  transform: 'scale(1.1)', // Add a hover effect
                },
              }}/>
            </Link>
            <Link href="#" target="_blank">
              <PinterestIcon sx={{
                color: '#ffffff', // Change the color
                fontSize: '30px', // Adjust the size
                '&:hover': {
                  color: '#01bb50', // Change the hover color
                  transform: 'scale(1.1)', // Add a hover effect
                },
              }}/>
            </Link>
            <Link href="#" target="_blank">
              <TwitterIcon sx={{
                color: '#ffffff', // Change the color
                fontSize: '30px', // Adjust the size
                '&:hover': {
                  color: '#01bb50', // Change the hover color
                  transform: 'scale(1.1)', // Add a hover effect
                },
              }}/>
            </Link>
            <Link href="#" target="_blank">
              <YouTubeIcon sx={{
                color: '#ffffff', // Change the color
                fontSize: '30px', // Adjust the size
                '&:hover': {
                  color: '#01bb50', // Change the hover color
                  transform: 'scale(1.1)', // Add a hover effect
                },
              }}/>
            </Link>
          </Stack>
        </Box>
      </Container>
    </Box>

    <Box sx={{ backgroundColor: "#0c0c0c", color: "#fff", padding: "2rem 0" }}>
      <Container>
        {/* <Typography variant="h4" textAlign="center" gutterBottom>
          Connect with us
        </Typography> */}
        {/* <Box textAlign="center" marginBottom="1rem">
          {["facebook", "instagram", "pinterest", "twitter", "youtube"].map((icon, index) => (
            <Link
              key={index}
              href="#"
              sx={{ margin: "0 0.5rem", color: "#fff", fontSize: "1.5rem" }}
              aria-label={`Follow us on ${icon}`}
            >
              <i className={`fa fa-${icon}`} aria-hidden="true"></i>
            </Link>
          ))}
        </Box> */}
        
        <Grid container spacing={6}>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="h3" sx={{ mb: 2, fontFamily: "Oswald, sans-serif" }}>About Us</Typography>
            <Typography variant="body1">
            {t('Our dining room with barrel vaults and sandstone pillars offers a fairytale setting.')}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="h3" sx={{ mb: 2, fontFamily: "Oswald, sans-serif" }}>{t('Address')}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>TheSpecialist, Schönnewitz 9, 01665 Käbschützal</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{t('Tel:')} 0175 - 169 76 45</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>Email: info@thespecialist</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Typography variant="h3" sx={{ mb: 2, fontFamily: "Oswald, sans-serif" }}>{t('Information')}</Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {["Home", "About Us", "Profile", "Blog", "Contact Us"].map((item, index) => (
                <Box textAlign="left" marginBottom="1rem">
                <li key={index}>
                  <Link href="#" color="inherit" underline="none" sx={{
                      fontSize: "1rem",
                      "&:hover": {
                        color: "#017432", fontWeight: 800,
                      },
                    }}>
                    {item}
                  </Link>
                </li>
                </Box>
              ))}
            </ul>
          </Grid>
        </Grid>
        
      </Container>
    </Box>
    <Box textAlign="center" marginTop="0rem" sx={{background: "#000000", padding: "1rem 0"}}>
      <Typography variant="body2">&copy; 2025 TheSpecialist</Typography>
    </Box>
    </>
  );
};

export default HomePage;
